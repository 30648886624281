import React from "react"
import { IoChevronForwardOutline } from "@react-icons/all-files/io5/IoChevronForwardOutline"

const RefButton = ({ src }) => {
  return (
    <a aria-label={`więcej informacji o usłudze`} className="carousel-button" href={src}>
      <span className="carousel-button-background"><IoChevronForwardOutline /></span>
      <span className="carousel-button-background">Więcej info</span>
    </a>
  )
}

export default RefButton
