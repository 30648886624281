import React, { useEffect, useState, useCallback, useRef } from "react"
import Slide from "./Slide"

const SWITCH_TIME = 10000

const Carousel = ({ services }) => {
  const slideHeight = useRef(null)
  const [current, setCurrent] = useState(0)
  const slidesAmount = services.length

  const assignColor = isYellow => {
    return isYellow ? "slide--yellow" : "slide--blue"
  }

  const nextSlide = () => {
    const slideNum = current === slidesAmount - 1 ? 0 : current + 1
    setCurrent(slideNum)
  }

  const prevSlide = () => {
    const slideNum = current === 0 ? slidesAmount - 1 : current - 1
    setCurrent(slideNum)
  }

  const change = useCallback(nextSlide, [nextSlide])

  useEffect(() => {
    const id = setInterval(change, SWITCH_TIME)

    return () => clearInterval(id)
  }, [current, change])

  const generateSlides = services.map(slide => (
    <Slide
      key={slide.id}
      slide={slide}
      next={nextSlide}
      prev={prevSlide}
      color={assignColor(slide.yellowColor)}
    />
  ))

  return (
    <section className="carousel">
      <div
        ref={slideHeight}
        className="carousel-list"
        style={{
          transform: `translateY(-${
            slideHeight.current ? slideHeight.current.clientHeight * current : 0
          }px)`,
        }}
      >
        {generateSlides}
      </div>
    </section>
  )
}

export default Carousel
