import React from "react"
import CarouselItem from "./CarouselItem"
import RefButton from "./RefButton"
import DescriptionPanel from "./DescriptionPanel"

const Slide = ({ slide, prev, next, color }) => (
  <article className={`slide ${color}`}>
    <CarouselItem slide={slide} next={next} prev={prev} />
    <DescriptionPanel slide={slide} />
    <RefButton src={`uslugi/${slide.slug}`} />
  </article>
)

export default Slide
