import React, { useContext } from "react"
import { IoCall } from "@react-icons/all-files/io5/IoCall"
import { IoMail } from "@react-icons/all-files/io5/IoMail"
import { ModalVisibility } from "../layout"

const DescriptionPanel = ({
  slide: {
    provider: { name, description, phone, email },
    asset,
  },
}) => {
  const { setModalVisibility } = useContext(ModalVisibility)

  return (
    <section className="panel">
      <div className="panel-item provider-data">
        <h3 className="item-header">{name}</h3>
        <p className="item-description">{description.substring(0, 100)}</p>
      </div>
      <div
        className="panel-item slide-photo"
        style={{ backgroundImage: `url(${asset[1].url})` }}
      >
        <div className="hero-bg"></div>
      </div>
      <div className="panel-item provider-data">
        <h3 className="item-header">Kontakt</h3>

        <div className="provider-data-cta">
          <a
            aria-label={`zadzwoń pod numer ${phone}`}
            className="item-contact"
            href={`tel:+48${phone}`}
          >
            Zadzwoń
          </a>
          <button
            aria-label={`wyślij maila`}
            className="item-contact"
            onClick={() => setModalVisibility(true)}
          >
            E-mail
          </button>
        </div>

        <div className="provider-data-cta-lg">
          <a aria-label={`zadzwoń pod numer ${phone}`} className="item-contact-lg contact-link" href={`tel:+48${phone}`}>
            <IoCall /> {phone}
          </a>
          <button
            aria-label={`wyślij maila pod adres ${email}`}
            className="item-contact-lg contact-link"
            onClick={() => setModalVisibility(true)}
          >
            <IoMail /> {email}
          </button>
        </div>
      </div>
    </section>
  )
}

export default DescriptionPanel
