import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Slider from "../components/slider/Slider"

const IndexPage = () => (
  <Layout>
    <Seo title="Strona główna" />
    <Slider />
  </Layout>
)

export default IndexPage
