import React from "react"
import Controllers from "./Controllers"

const CarouselItem = ({slide:{name, slug, asset}, next, prev}) => {
  return (
    <div className="content-item">
      <header className="item-header">
        <h2 className="header-title">
          <span className="title-brand">hydromat</span>
          <span className="title-service">{name}</span>
        </h2>
        <Controllers next={next} prev={prev} />
      </header>
      <img className="item-image" src={asset[0].url} alt={slug} />
    </div>
  )
}

export default CarouselItem
