import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Carousel from "./Carousel"

const Slider = () => (
  <main className="wrapper slider">
    <StaticQuery
      query={graphql`
        query {
          hydromat {
            services {
              id
              name
              description
              yellowColor
              provider {
                id
                name
                phone
                email
                description
              }
              slug
              asset {
                ... on HYDROMAT_Asset {
                  id
                  url
                }
              }
            }
          }
        }
      `}
      render={({ hydromat: { services } }) => <Carousel services={services} />}
    />
  </main>
)

export default Slider
